import { render, staticRenderFns } from "./MenuList.vue?vue&type=template&id=716ff7d0&scoped=true&"
import script from "./MenuList.vue?vue&type=script&lang=ts&"
export * from "./MenuList.vue?vue&type=script&lang=ts&"
import style0 from "./MenuList.vue?vue&type=style&index=0&id=716ff7d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "716ff7d0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/33/a/NuxtApp/components/base/Icon.vue').default,BaseOverlay: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/33/a/NuxtApp/components/base/Overlay.vue').default})
